import React, { useState } from 'react';

const LazyImage = ({ src, alt, className }) => {
    const [isLoading, setIsLoading] = useState(true);

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    return (
        <div className="image-container" style={{ position: 'relative' }}>
            {isLoading && (
                <div className="loading-spinner">
                    {/* Replace with your spinner icon */}
                    <div className="spinner"></div>
                </div>
            )}
            <img 
                src={src} 
                alt={alt} 
                className={`${className} ${isLoading ? 'hidden' : ''}`} 
                onLoad={handleImageLoad} 
            />
        </div>
    );
};

export default LazyImage;
