import React from 'react'
import './PartitionContent.css'
import { FaShieldAlt, FaCubes, FaTools } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './MarbleBanner.css';
import LazyImage from '../components/LazyImage'; // Adjust path if necessary


function MarbleFloorContent() {
    const images = [
        'Marble (2).jpg',
        'Marble (3).jpg',
        'Marble (4).jpg',
        'Marble (5).jpg',
        'Marble (6).jpg',
        'Marble (7).jpg',
    ];


    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };


    const updateIndicators = (index) => {
        const indicators = document.querySelectorAll(".slide-counter");
        indicators.forEach((indicator, i) => {
            if (i === index) {
                indicator.style.backgroundColor = "rgb(51, 89, 119)";
                indicator.style.height = "11px";
                indicator.style.width = "11px";
            } else {
                indicator.style.backgroundColor = "white";
                indicator.style.height = "8px";
                indicator.style.width = "8px";
            }
        });
    };

    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    const handleEffect = () => {
        const bar1 = document.querySelector('.bar-inner-1');
        bar1.style.width = '87%';
        const bar2 = document.querySelector('.bar-inner-2');
        bar2.style.width = '92%';
        const bar3 = document.querySelector('.bar-inner-3');
        bar3.style.width = '95%';
        const bar4 = document.querySelector('.bar-inner-4');
        bar4.style.width = '90%';
    }

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                handleEffect();
            }, 500);
        }
    }, [inView])

    useEffect(() => {
        updateIndicators(currentIndex);
    }, [currentIndex]);

  return (
    <div className='PartitionContent'>
        <div className="MarbleBanner">
                <div className='partition-banner-inner'>
                    <h1 className='partition-head'>
                        Marble Flooring
                    </h1>
                </div>
            </div>
            <div className='partition-container'>
                <div className='partition-top'>
                    <h1 className='partition-head-1'>
                    Marble Flooring Services: Luxury and Durability
                    </h1>
                    <p className='partition-para'>
                    Marble flooring is the epitome of luxury and timeless elegance. At ND Marble Flooring, we offer premium marble installation services that enhance the beauty and value of any space. Whether you're looking to add a touch of sophistication to your home, office, or commercial property, our skilled team ensures flawless installation and a long-lasting finish. Our marble flooring solutions are designed to complement both classic and modern interiors, providing a durable and visually stunning surface for high-traffic areas.
                    </p>
                </div>
                <div className='slider' >
                    <button className='slider-button prev' onClick={prevSlide}>
                        &#8249;
                    </button>
                    <div className='slider-images'>
                        {images.slice(currentIndex, currentIndex + 3).map((image, index) => (
                            <div key={index} className='slider-image' >
                                <LazyImage src={image} alt={`Slide ${index}`} />
                            </div>
                        ))}
                    </div>
                    <button className='slider-button next' onClick={nextSlide}>
                        &#8250;
                    </button>
                </div>
                <div className="slider-indicators">
                    {images.map((_, index) => (
                        <div
                            key={index}
                            className={`slide-counter`}
                        ></div>
                    ))}
                </div>
            </div>
            <div className='partition-content-top'>
                <h1 className='partition-head-2'>
                Why is Marble Flooring Important?
                </h1>
                <p className='partition-para'>
                Marble flooring is one of the most luxurious and durable flooring options available, made from natural stone. It’s known for its stunning veining patterns and rich, polished surface that can add an air of elegance to any space. Marble comes in a wide array of colors and patterns, including whites, creams, browns, and blacks, each unique in its design. Its smooth, polished surface provides a lustrous sheen, making it a favored material for high-end homes, offices, and hotels. Marble floors not only add visual appeal but also offer exceptional durability. The material is highly resistant to wear and tear, making it ideal for areas with heavy foot traffic, such as hallways, kitchens, and entryways. The natural cooling properties of marble make it a great choice for warmer climates, as it helps keep the room temperature regulated. Additionally, marble is highly versatile and can be used in various settings, from modern contemporary homes to more traditional spaces. The refined look and timeless appeal of marble flooring make it an investment that can increase the value of your property.
                </p>
                <h1 className='partition-head-2'>
                Benefits of Using Marble Flooring
                </h1>
                <ul>
                    <li>Adds a luxurious, sophisticated touch to any room.</li>
                    <li>Naturally resilient to heavy traffic and everyday wear and tear.</li>
                    <li>With proper sealing, marble is relatively easy to clean and maintain.</li>
                    <li>Offers versatility in design with various natural tones and patterns.</li>
                    <li>Marble flooring adds value to your home or business, making it a worthwhile investment.</li>
                </ul>
            </div>

            <div className='content-container'>
                <div className='current-section'>
                    <FaShieldAlt color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Durable Gypsum Walls
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
                <div className='current-section'>
                    <FaCubes color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Elegant Design
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
                <div className='current-section'>
                    <FaTools color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Easy Installation
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
            </div>
            <div className='partition-content-bottom' >
                <div className='content-bottom-left'>
                    <h1 className='partition-head-2'>
                    Step into elegance with the unmatched beauty of marble flooring.
                    </h1>
                    <p className='partition-para-bottom'>
                    At ND Marble Flooring, we specialize in installing premium marble floors that add a touch of sophistication and luxury to any setting. Whether you’re renovating your home, office, or commercial space, marble floors provide a sleek, polished finish that’s durable and easy to maintain. Each tile is a piece of art, with natural veining that’s unique to every stone. Our team ensures a precise, expert installation that highlights the beauty of the marble, leaving you with a flooring solution that’s both functional and visually stunning. Choose marble for a timeless and elegant flooring option that will never go out of style.
                    </p>
                    <button className='read-more'>
                        Contact Now
                    </button>
                </div>
                <div className='content-bottom-next'>
                    <LazyImage src='Marble.jpg' className='content-partiton-im' />
                </div>
            </div>
    </div>
  )
}

export default MarbleFloorContent