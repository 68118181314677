import React from 'react'
import './PartitionContent.css'
import { FaShieldAlt, FaCubes, FaTools } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './CeilingContent.css';
import LazyImage from '../components/LazyImage'; // Adjust path if necessary


function CeilingContent() {

    const images = [
        'c1.jpg',
        'c2.jpg',
        'c3.jpg',
        'c4.jpg',
        'c5.jpg',
        'c6.jpg',
    ];


    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };


    const updateIndicators = (index) => {
        const indicators = document.querySelectorAll(".slide-counter");
        indicators.forEach((indicator, i) => {
            if (i === index) {
                indicator.style.backgroundColor = "rgb(51, 89, 119)";
                indicator.style.height = "11px";
                indicator.style.width = "11px";
            } else {
                indicator.style.backgroundColor = "white";
                indicator.style.height = "8px";
                indicator.style.width = "8px";
            }
        });
    };

    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    const handleEffect = () => {
        const bar1 = document.querySelector('.bar-inner-1');
        bar1.style.width = '87%';
        const bar2 = document.querySelector('.bar-inner-2');
        bar2.style.width = '92%';
        const bar3 = document.querySelector('.bar-inner-3');
        bar3.style.width = '95%';
        const bar4 = document.querySelector('.bar-inner-4');
        bar4.style.width = '90%';
    }

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                handleEffect();
            }, 500);
        }
    }, [inView])

    useEffect(() => {
        updateIndicators(currentIndex);
    }, [currentIndex]);
    return (
        <div className='PartitionContent'>
            <div className="CeilingBanner">
                <div className='partition-banner-inner'>
                    <h1 className='partition-head'>
                        Gypsum Ceiling
                    </h1>
                </div>
            </div>
            <div className='partition-container'>
                <div className='partition-top'>
                    <h1 className='partition-head-1'>
                        False Ceiling Services: Elevate Your Space
                    </h1>
                    <p className='partition-para'>
                        At ND False Ceiling, we specialize in designing and installing high-quality false ceilings that add elegance, style, and functionality to any space. Whether you need to conceal wires, improve acoustics, or add a decorative touch to your rooms, our expert team provides tailored solutions to meet your needs. Our false ceilings are made from top-notch materials, ensuring a seamless finish and long-lasting durability. We offer designs that cater to both modern and traditional interiors, creating a visually appealing environment while optimizing lighting and airflow. Trust us to deliver a false ceiling that enhances the ambiance and practicality of your space.
                    </p>
                </div>
                <div className='slider' >
                    <button className='slider-button prev' onClick={prevSlide}>
                        &#8249;
                    </button>
                    <div className='slider-images'>
                        {images.slice(currentIndex, currentIndex + 3).map((image, index) => (
                            <div key={index} className='slider-image'>
                                <LazyImage src={image} alt={`Slide ${index}`} loading="lazy"/>
                            </div>
                        ))}
                    </div>
                    <button className='slider-button next' onClick={nextSlide}>
                        &#8250;
                    </button>
                </div>
                <div className="slider-indicators">
                    {images.map((_, index) => (
                        <div
                            key={index}
                            className={`slide-counter`}
                        ></div>
                    ))}
                </div>
            </div>
            <div className='partition-content-top'>
                <h1 className='partition-head-2'>
                    What is False Ceiling?
                </h1>
                <p className='partition-para'>
                    A false ceiling, also known as a dropped or suspended ceiling, is a secondary ceiling installed below the main structural ceiling. This solution is widely used in both residential and commercial spaces for its versatility in enhancing the look of a room while offering multiple functional benefits. False ceilings not only provide an aesthetic upgrade by offering smooth, modern finishes but also serve to conceal electrical wiring, ducts, and pipes, giving the space a cleaner, more organized look. Additionally, false ceilings play a key role in improving acoustics, reducing noise pollution, and enhancing the sound experience in a room. They can also be designed to accommodate lighting fixtures, such as recessed lights, chandeliers, and track lighting, which are integrated seamlessly into the ceiling for an added touch of elegance. Whether made from materials like gypsum, mineral fiber, or aluminum, false ceilings are an essential element in modern interior design, offering both practicality and beauty. Beyond their decorative role, they help regulate the indoor climate by offering insulation, making rooms cooler in the summer and warmer in the winter.
                </p>
                <h1 className='partition-head-2'>
                    Benefits of Using False Ceiling
                </h1>
                <ul>
                    <li>Elevates the appearance of any space with smooth, uniform finishes.</li>
                    <li>Perfect for hiding electrical cables, ducts, and other utilities.</li>
                    <li>Allows for recessed or ambient lighting solutions.</li>
                    <li>Enhances soundproofing and noise reduction.</li>
                    <li>Simple to clean and maintain over time.</li>
                </ul>
            </div>

            <div className='content-container'>
                <div className='current-section'>
                    <FaShieldAlt color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Durable Gypsum Walls
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
                <div className='current-section'>
                    <FaCubes color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Elegant Design
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
                <div className='current-section'>
                    <FaTools color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Easy Installation
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
            </div>
            <div className='partition-content-bottom' >
                <div className='content-bottom-left'>
                    <h1 className='partition-head-2'>
                    Don’t you want a space that blends functionality with style?
                    </h1>
                    <p className='partition-para-bottom'>
                    Our false ceiling services offer the perfect combination of practicality and elegance, creating a unique atmosphere for any room. With our expert team at ND False Ceiling, we craft custom designs that conceal wires, pipes, and other elements while enhancing your space with innovative lighting solutions. The variety of materials we use, from gypsum to mineral fiber, guarantees a long-lasting, durable ceiling that complements both modern and traditional interiors. Whether you seek acoustic improvement, better lighting, or simply want a clean, seamless look, our false ceilings bring out the best in your space.
                    </p>
                    <button className='read-more'>
                        Contact Now
                    </button>
                </div>
                <div className='content-bottom-next'>
                    <LazyImage src='assets2/ceil1.jpeg' className='content-partiton-im' loading="lazy"/>
                </div>
            </div>
        </div>
    )
}

export default CeilingContent