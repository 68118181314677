import React from 'react'
import './PartitionContent.css'
import { FaShieldAlt, FaCubes, FaTools } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './GrassContent.css';
import LazyImage from '../components/LazyImage'; // Adjust path if necessary


function GrassContent() {
    const images = [
        'o2.jpeg',
        '0000.jpg',
        'o6.jpg',
        'o3.jpg',
        'o4.jpg',
        'o0.jpg',
        'o6.jpg',
        'o9.jpg',
    ];


    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };


    const updateIndicators = (index) => {
        const indicators = document.querySelectorAll(".slide-counter");
        indicators.forEach((indicator, i) => {
            if (i === index) {
                indicator.style.backgroundColor = "rgb(51, 89, 119)";
                indicator.style.height = "11px";
                indicator.style.width = "11px";
            } else {
                indicator.style.backgroundColor = "white";
                indicator.style.height = "8px";
                indicator.style.width = "8px";
            }
        });
    };

    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    const handleEffect = () => {
        const bar1 = document.querySelector('.bar-inner-1');
        bar1.style.width = '87%';
        const bar2 = document.querySelector('.bar-inner-2');
        bar2.style.width = '92%';
        const bar3 = document.querySelector('.bar-inner-3');
        bar3.style.width = '95%';
        const bar4 = document.querySelector('.bar-inner-4');
        bar4.style.width = '90%';
    }

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                handleEffect();
            }, 500);
        }
    }, [inView])

    useEffect(() => {
        updateIndicators(currentIndex);
    }, [currentIndex]);

    return (
        <div className='PartitionContent'>
            <div className="GrassBanner">
                <div className='partition-banner-inner'>
                    <h1 className='partition-head'>
                        Artificial Grass
                    </h1>
                </div>
            </div>
            <div className='partition-container'>
                <div className='partition-top'>
                    <h1 className='partition-head-1'>
                    Artificial Grass Installation: Green and Maintenance-Free
                    </h1>
                    <p className='partition-para'>
                    ND Artificial Grass offers premium synthetic turf installation services that provide a lush, green lawn year-round with minimal maintenance. Whether you're looking to enhance your garden, sports field, or commercial space, our high-quality artificial grass is designed to mimic natural grass while offering durability, easy care, and an environmentally friendly solution.
                    </p>
                </div>
                <div className='slider' >
                    <button className='slider-button prev' onClick={prevSlide}>
                        &#8249;
                    </button>
                    <div className='slider-images'>
                        {images.slice(currentIndex, currentIndex + 3).map((image, index) => (
                            <div key={index} className='slider-image'>
                                <LazyImage src={image} alt={`Slide ${index}`} />
                            </div>
                        ))}
                    </div>
                    <button className='slider-button next' onClick={nextSlide}>
                        &#8250;
                    </button>
                </div>
                <div className="slider-indicators">
                    {images.map((_, index) => (
                        <div
                            key={index}
                            className={`slide-counter`}
                        ></div>
                    ))}
                </div>
            </div>
            <div className='partition-content-top'>
                <h1 className='partition-head-2'>
                Wouldn’t you love a lawn that stays lush all year round without the hassle?
                </h1>
                <p className='partition-para'>
                Artificial grass, also known as synthetic turf, is a man-made surface designed to replicate the look, feel, and texture of natural grass. It is made from high-quality materials such as polyethylene and nylon, offering a durable and long-lasting solution for those looking for an easy-to-maintain lawn. Unlike natural grass, artificial grass does not require watering, mowing, or fertilizing, making it an eco-friendly and cost-effective alternative. This type of grass is ideal for areas where natural grass struggles to grow, such as in arid climates or on rooftops, playgrounds, and sports fields. Artificial grass is designed to be UV-resistant, ensuring it maintains its vibrant color and texture even after prolonged exposure to sunlight. It also provides excellent drainage and is safe for children and pets. Artificial grass is often used in residential, commercial, and recreational spaces, offering a lush, green aesthetic year-round without the need for constant maintenance.
                </p>
                <h1 className='partition-head-2'>
                Benefits of Using Artificial Grass
                </h1>
                <ul>
                    <li>No mowing, watering, or fertilizing needed, saving you time and money.</li>
                    <li>High resistance to wear, UV rays, and heavy foot traffic.</li>
                    <li> Reduces water consumption and eliminates the need for chemicals.</li>
                    <li> Ideal for lawns, gardens, playgrounds, sports fields, and even balconies.</li>
                    <li> Safe and soft underfoot for pets to enjoy.</li>
                </ul>
            </div>

            <div className='content-container'>
                <div className='current-section'>
                    <FaShieldAlt color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Durable Gypsum Walls
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
                <div className='current-section'>
                    <FaCubes color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Elegant Design
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
                <div className='current-section'>
                    <FaTools color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Easy Installation
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
            </div>
            <div className='partition-content-bottom' >
                <div className='content-bottom-left'>
                    <h1 className='partition-head-2'>
                        Don't You Want Such a Beautiful Interior Design?
                    </h1>
                    <p className='partition-para-bottom'>
                    With ND Artificial Grass, you can enjoy a vibrant, green space without the need for constant upkeep. Our high-quality synthetic grass looks and feels just like real grass, giving you the perfect solution for gardens, balconies, or outdoor areas. Say goodbye to mowing, watering, and fertilizing, and embrace a more eco-friendly, maintenance-free lawn. Our artificial grass is UV-resistant and built to withstand the elements, ensuring that your outdoor space remains beautiful all year. Whether you're enhancing your home’s curb appeal or creating a safe playground for kids and pets, artificial grass provides a perfect solution.
                    </p>
                    <button className='read-more'>
                        Contact Now
                    </button>
                </div>
                <div className='content-bottom-next'>
                    <LazyImage src='assets2/grass2.jpeg' className='content-partiton-im' />
                </div>
            </div>
        </div>
    )
}

export default GrassContent