import React from 'react'
import './PartitionContent.css'
import { FaShieldAlt, FaCubes, FaTools } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './TileContent.css';
import LazyImage from '../components/LazyImage'; // Adjust path if necessary


function TileContent() {
    const images = [
        't (1).jpg',
        't (10).jpg',
        't (3).jpg',
        't (4).jpg',
        't (5).jpg',
        't (6).jpg',
        't (7).jpg',
        't (9).jpg',
    ];
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };


    const updateIndicators = (index) => {
        const indicators = document.querySelectorAll(".slide-counter");
        indicators.forEach((indicator, i) => {
            if (i === index) {
                indicator.style.backgroundColor = "rgb(51, 89, 119)";
                indicator.style.height = "11px";
                indicator.style.width = "11px";
            } else {
                indicator.style.backgroundColor = "white";
                indicator.style.height = "8px";
                indicator.style.width = "8px";
            }
        });
    };

    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    const handleEffect = () => {
        const bar1 = document.querySelector('.bar-inner-1');
        bar1.style.width = '87%';
        const bar2 = document.querySelector('.bar-inner-2');
        bar2.style.width = '92%';
        const bar3 = document.querySelector('.bar-inner-3');
        bar3.style.width = '95%';
        const bar4 = document.querySelector('.bar-inner-4');
        bar4.style.width = '90%';
    }

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                handleEffect();
            }, 500);
        }
    }, [inView])

    useEffect(() => {
        updateIndicators(currentIndex);
    }, [currentIndex]);

    return (
        <div className='PartitionContent'>
            <div className="TileBanner">
                <div className='partition-banner-inner'>
                    <h1 className='partition-head'>
                        Tile Fixing
                    </h1>
                </div>
            </div>
            <div className='partition-container'>
                <div className='partition-top'>
                    <h1 className='partition-head-1'>
                    Tile Fixing Services: Precision and Quality
                    </h1>
                    <p className='partition-para'>
                    At ND Tile Fixing, we offer professional tile installation and repair services that transform your floors, walls, and backsplashes into stunning visual features. Our team ensures precise and seamless tile placement, whether you're remodeling your kitchen, bathroom, or outdoor spaces. We work with a variety of tile materials, including ceramic, porcelain, and natural stone, to create durable and attractive surfaces that are built to last.
                    </p>
                </div>
                <div className='slider' >
                    <button className='slider-button prev' onClick={prevSlide}>
                        &#8249;
                    </button>
                    <div className='slider-images'>
                        {images.slice(currentIndex, currentIndex + 3).map((image, index) => (
                            <div key={index} className='slider-image'>
                                <LazyImage src={image} alt={`Slide ${index}`} />
                            </div>
                        ))}
                    </div>
                    <button className='slider-button next' onClick={nextSlide}>
                        &#8250;
                    </button>
                </div>
                <div className="slider-indicators">
                    {images.map((_, index) => (
                        <div
                            key={index}
                            className={`slide-counter`}
                        ></div>
                    ))}
                </div>
            </div>
            <div className='partition-content-top'>
                <h1 className='partition-head-2'>
                Want your tiles installed flawlessly, creating a sleek and stylish surface?
                </h1>
                <p className='partition-para'>
                Tile fixing involves the professional installation of tiles onto surfaces like floors, walls, countertops, and backsplashes. This process is essential for enhancing the functionality, durability, and aesthetics of various spaces. Tiles are available in a wide range of materials, such as ceramic, porcelain, marble, and natural stone, each offering unique benefits in terms of texture, color, and resistance to damage. The tile fixing process begins with surface preparation, ensuring the area is clean, level, and ready for tile application. Tiles are then installed with precise measurements and alignment, ensuring an even finish. The process concludes with grouting and sealing, which ensures tiles stay in place and are resistant to moisture. Tile fixing is commonly used in high-traffic areas such as bathrooms, kitchens, and hallways, where tiles provide long-lasting, easy-to-clean surfaces.
                </p>
                <h1 className='partition-head-2'>
                Benefits of Using Tiling 
                </h1>
                <ul>
                    <li>Tiles are tough, resistant to wear and tear, and easy to clean.</li>
                    <li>Available in a wide range of materials, colors, and styles for different aesthetics.</li>
                    <li> Ideal for wet areas like bathrooms and kitchens.</li>
                    <li>Well-installed tiles can significantly enhance the overall appearance of a space.</li>
                    <li>Tiles are easy to maintain, requiring only regular cleaning and occasional regrouting.</li>
                </ul>
            </div>

            <div className='content-container'>
                <div className='current-section'>
                    <FaShieldAlt color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Durable Gypsum Walls
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
                <div className='current-section'>
                    <FaCubes color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Elegant Design
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
                <div className='current-section'>
                    <FaTools color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Easy Installation
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
            </div>
            <div className='partition-content-bottom' >
                <div className='content-bottom-left'>
                    <h1 className='partition-head-2'>
                    Want your tiles installed flawlessly, creating a sleek and stylish surface?
                    </h1>
                    <p className='partition-para-bottom'>
                    Our tile fixing services at ND Tile Fixing ensure that your floors, walls, and backsplashes are flawlessly installed, creating the perfect foundation for any room. We work with a wide variety of materials, including ceramic, porcelain, and natural stone, to give your space a high-end finish that will last for years. Whether it’s for a bathroom, kitchen, or living room, our expert team handles everything from surface preparation to the final grout application, ensuring a seamless and durable result. Enhance the functionality and aesthetic of your home with expertly installed tiles that will stand the test of time.
                    </p>
                    <button className='read-more'>
                        Contact Now
                    </button>
                </div>
                <div className='content-bottom-next'>
                    <LazyImage src='t (1).jpg' className='content-partiton-im' />
                </div>
            </div>
        </div>
    )
}

export default TileContent