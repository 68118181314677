import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer'
import './Projects2.css';

export default function Projects2() {

    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true
    });
    function HandleProjects(){
        const array = [
            {im: '.imgContainer1', value: '1'},
            {im: '.imgContainer2', value: '2'},
            {im: '.imgContainer3', value: '3'}
        ];
        array.forEach((element) =>{
            const el = document.querySelector(element.im);
            if(el){
                 el.style.opacity = '1';
            }
        });
    }
    function HandleEffect() {
        const elements = [
            { selector: '.BarPercent1', width: '95%', backColor: 'rgb(75, 160, 172)' },
            { selector: '.BarPercent2', width: '90%', backColor: 'rgb(65, 93, 128)' },
            { selector: '.BarPercent3', width: '92%', backColor: 'rgb(75, 160, 172)' },
            { selector: '.BarPercent4', width: '92%', backColor: 'rgb(65, 93, 128)'},
            { selector: '.BarPercent5', width: '90%', backColor: 'rgb(75, 160, 172)' }
        ];

        elements.forEach((element) => {
            const el = document.querySelector(element.selector);
            if (el) {
                el.style.width = element.width;
                el.style.backgroundColor = element.backColor;
            }
        });
    }
    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                HandleEffect();
                HandleProjects();
            }, 200);
        }
    }, [inView]);

    return (
        <div className='Projects2' id='projects' ref={ref}>
            <div className='Projects2Left'>
                <div>
                    <h2 className='Project2Head' >
                        Why Choose Us?
                    </h2>
                </div>
                <div>
                    <h5 className='Project2SubHead'>
                        Our Expertise in Development
                    </h5>
                </div>
                <div className='Bars'>
                    <div className='MyBar'>
                        <div className='BarText'>
                            Optimized Needs
                        </div>
                        <div className='Bar'>
                            <div className='BarPercent1'>

                            </div>
                        </div>
                        <div className='BarValue'>
                            Maximizing efficiency for optimal performance
                        </div>
                    </div>
                    <div className='MyBar'>
                        <div className='BarText'>
                            Clients' Satisfaction
                        </div>
                        <div className='Bar'>
                            <div className='BarPercent2'>

                            </div>
                        </div>
                        <div className='BarValue'>
                            Your satisfaction is our ultimate goal
                        </div>
                    </div>
                    <div className='MyBar'>
                        <div className='BarText'>
                            Highly Attractive Designs
                        </div>
                        <div className='Bar'>
                            <div className='BarPercent3'>

                            </div>
                        </div>
                        <div className='BarValue'>
                            Crafting visually stunning designs.
                        </div>
                    </div>
                    <div className='MyBar'>
                        <div className='BarText'>
                            Expectaions Meet
                        </div>
                        <div className='Bar'>
                            <div className='BarPercent4'>

                            </div>
                        </div>
                        <div className='BarValue'>
                            Exceeding expectations with every project.
                        </div>
                    </div>
                    <div className='MyBar'>
                        <div className='BarText'>
                            Custom Designs
                        </div>
                        <div className='Bar'>
                            <div className='BarPercent5'>

                            </div>
                        </div>
                        <div className='BarValue'>
                            Designs uniquely tailored to your needs
                        </div>
                    </div>
                </div>
                <div>

                </div>
            </div>
            <div className='Projects2Next'>
                <div className='MyProjects'>
                    <div className='MyProjectsTop'>
                        <div className='imgContainer1'>
                            <img className='imProject1' src='assets2/updates (1).png' alt='Zaarana Project' />
                            <p className='imgCaption'>.</p>
                        </div>
                        <div className='imgContainer2'>
                            <img className='imProject' src='assets2/updates (2).png' alt='Debates Project' />
                            <p className='imgCaption'></p>
                        </div>
                    </div>
                    <div className='MyProjectsBottom'>
                        <div className='imgContainer3'>
                            <img className='imProject2' src='assets2/updates (3).png' alt='IP Author Project' />
                            <p className='imgCaption1'></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};