import React from 'react'
import './Prefooter.css'
import LazyImage from '../components/LazyImage'; // Adjust path if necessary

import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaWhatsapp, FaPhone } from 'react-icons/fa';

function Prefooter() {
  return (
    <div className='Prefooter'>
      <div className='prefooter-left'>
        <LazyImage src="icon-1.png" alt="icon" className='icon-1' />
        <div className='prefooter-icons'>
          <FaFacebook color="#082944" size={20} />
          <FaInstagram color="#082944" size={20} />
          <FaTwitter color="#082944" size={20} />
          <FaWhatsapp color="#082944" size={20} />
        </div>
      </div>
      <div className='prefooter-next'>
        <h1 className='prefooter-head'>
          Business Details
        </h1>
        <p className='head-text'>
          Working Hours
        </p>
        <p className='sub-text'>
          8:00 am to 10:00 pm
        </p>
        <p className='head-text'>
          Address
        </p>
        <p className='sub-text'>
          ND Gypsum & Partition Services, Abu Hail - Dubai - United Arab Emirates
        </p>
        <p className='head-text'>
          Call Us
        </p>
        <div className='prefooter-btn-row'>
          <button
            className="read-more"
            onClick={() => (window.location.href = "tel:+971568522584")}
          >
            +971568522584
          </button>
          <button
            className="read-more"
            onClick={() => (window.location.href = "tel:+971504458762")}
          >
            +971504458762
          </button>
          <button
            className="read-more"
            onClick={() => (window.location.href = "tel:+971543177108")}
          >
            +971543177108
          </button>

        </div>
      </div>
    </div>
  )
}

export default Prefooter