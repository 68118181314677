import { useEffect, useState } from 'react';
import React from 'react'
import './HeaderBottom.css';
import { useInView } from 'react-intersection-observer';

function HeaderBottom() {

    const [ref, inView] = useInView({ threshold: 0.2 });
    const [color, setColor] = useState('#219b4e');

    const handleEffect = () => {
        const element = document.querySelector('#header-bottom-next');
        if (element) {
            element.style.opacity = "1";
        }
    };

    const handleCircle = () => {
        const element = document.querySelector('#scroll-icon');
        if (element) {
            element.style.backgroundColor = color; 
        }
    };

    useEffect(() => {
        if (inView) {
            const timeout = setTimeout(() => {
                handleEffect();
                const interval = setInterval(() => {
                    setColor(prevColor => (prevColor === '#219b4e' ? '#ffd000' : '#219b4e'));
                }, 3000);

                return () => clearInterval(interval); 
            }, 2000);

            return () => clearTimeout(timeout); 
        }
    }, [inView]);

    useEffect(() => {
        handleCircle(); 
    }, [color]);



    return (
        <div className='header-bottom-1' ref={ref}>
            <div className='rect-2'>

            </div>
            <div className='header-bottom-next' id='header-bottom-next'>
                <div className='scroll-icon' id='scroll-icon'>

                </div>
                <p className='scroll-text'>
                    Scroll for more
                </p>
            </div>
        </div>
    )
}

export default HeaderBottom