import React from 'react'
import './PartitionContent.css'
import { FaShieldAlt, FaCubes, FaTools } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './ElectricalContent.css';
import LazyImage from '../components/LazyImage'; 


function GrassContent() {
    const images = [
        'e3.jpg',
        'e1.jpeg',
        'e8.jpg',
        'e2.jpg',
        'e4.jpg',
        'e8.jpg', 
        'e7.jpg', 
        'e0.jpg',  
        'e3.jpg', 
    ];


    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };


    const updateIndicators = (index) => {
        const indicators = document.querySelectorAll(".slide-counter");
        indicators.forEach((indicator, i) => {
            if (i === index) {
                indicator.style.backgroundColor = "rgb(51, 89, 119)";
                indicator.style.height = "11px";
                indicator.style.width = "11px";
            } else {
                indicator.style.backgroundColor = "white";
                indicator.style.height = "8px";
                indicator.style.width = "8px";
            }
        });
    };

    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    const handleEffect = () => {
        const bar1 = document.querySelector('.bar-inner-1');
        bar1.style.width = '87%';
        const bar2 = document.querySelector('.bar-inner-2');
        bar2.style.width = '92%';
        const bar3 = document.querySelector('.bar-inner-3');
        bar3.style.width = '95%';
        const bar4 = document.querySelector('.bar-inner-4');
        bar4.style.width = '90%';
    }

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                handleEffect();
            }, 500);
        }
    }, [inView])

    useEffect(() => {
        updateIndicators(currentIndex);
    }, [currentIndex]);

  return (
    <div className='PartitionContent'>
        <div className="GrassBanner">
                <div className='partition-banner-inner'>
                    <h1 className='partition-head'>
                     Electrical Services
                    </h1>
                </div>
            </div>
            <div className='partition-container'>
                <div className='partition-top'>
                    <h1 className='partition-head-1'>
                    Electrician Services: Safe and Reliable Solutions
                    </h1>
                    <p className='partition-para'>
                    At ND Electrician Services, we offer a comprehensive range of electrical solutions for your home and business needs. Our licensed and experienced electricians are dedicated to providing safe, efficient, and reliable electrical services. Whether you need electrical installations, repairs, or upgrades, we ensure that every job is completed to the highest standards. We work with both residential and commercial clients, ensuring that all electrical systems are safe, up to code, and functioning optimally.
                    </p>
                </div>
                <div className='slider' >
                    <button className='slider-button prev' onClick={prevSlide}>
                        &#8249;
                    </button>
                    <div className='slider-images'>
                        {images.slice(currentIndex, currentIndex + 3).map((image, index) => (
                            <div key={index} className='slider-image'>
                                <LazyImage src={image} alt={`Slide ${index}`} />
                            </div>
                        ))}
                    </div>
                    <button className='slider-button next' onClick={nextSlide}>
                        &#8250;
                    </button>
                </div>
                <div className="slider-indicators">
                    {images.map((_, index) => (
                        <div
                            key={index}
                            className={`slide-counter`}
                        ></div>
                    ))}
                </div>
            </div>
            <div className='partition-content-top'>
                <h1 className='partition-head-2'>
                Is your electrical system working at its best?
                </h1>
                <p className='partition-para'>
                Electrician services refer to professional electrical work that ensures the safety, reliability, and efficiency of your electrical systems. These services encompass a wide range of tasks, from new installations to repairs and upgrades, all carried out by certified electricians. Some of the most common electrical services include wiring, circuit breaker installation, lighting design, and troubleshooting. Whether you’re building a new home or upgrading an existing electrical system, our electricians provide expert services that meet safety codes and regulations. Electrical systems are critical for modern living and business operations, and having a licensed professional handle the installation or repair of these systems is crucial for both safety and functionality. With years of expertise, we offer solutions that maximize electrical efficiency, reduce the risk of faults, and improve the safety and convenience of your property. Our team uses state-of-the-art tools and techniques to provide quick, reliable service without compromising on quality. From emergency repairs to large-scale electrical installations, our services cover all your electrical needs.
                </p>
                <h1 className='partition-head-2'>
                Benefits of Using Our Electrician Service
                </h1>
                <ul>
                    <li>Our electricians bring years of specialized knowledge to every project.</li>
                    <li>All work is carried out with a focus on safety, adhering to local electrical codes.</li>
                    <li>We provide reliable solutions at competitive prices.</li>
                    <li>Help reduce electricity consumption with smart installations.</li>
                    <li>Our electricians are available for urgent issues that require immediate attention.</li>
                </ul>
            </div>

            <div className='content-container'>
                <div className='current-section'>
                    <FaShieldAlt color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Durable Gypsum Walls
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
                <div className='current-section'>
                    <FaCubes color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Elegant Design
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
                <div className='current-section'>
                    <FaTools color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Easy Installation
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
            </div>
            <div className='partition-content-bottom' >
                <div className='content-bottom-left'>
                    <h1 className='partition-head-2'>
                        Don't You Want Such a Reliable and Durable Installation?
                    </h1>
                    <p className='partition-para-bottom'>
                    ND Electrician Services provides comprehensive electrical solutions, ensuring that your home or office is powered safely and efficiently. Our certified electricians handle everything from simple repairs to full electrical installations. We work quickly to address your electrical needs while maintaining the highest safety standards. From wiring to circuit breaker installation and lighting design, we’ve got you covered. Our team also helps improve your electrical system’s efficiency, making sure that your home is energy-efficient and safe from potential hazards. Trust us for all your electrical needs, big or small.
                    </p>
                    <button className='read-more'>
                        Contact Now
                    </button>
                </div>
                <div className='content-bottom-next'>
                    <LazyImage src='e4.jpg' className='content-partiton-im' />
                </div>
            </div>
    </div>
  )
}

export default GrassContent