import React from 'react'
import { useEffect } from 'react';
import './Products.css';
import { InView, useInView } from 'react-intersection-observer';
import { FaArrowRight } from 'react-icons/fa';

function Products() {

    const [ref1, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true,
    })

    const handleEfect = () => {
        const bar1 = document.querySelector('#bar1');
        const bar2 = document.querySelector('#bar2');
        const bar3 = document.querySelector('#bar3');
        const bar4 = document.querySelector('#bar4');
        bar1.style.width = "100%";
        bar2.style.width = "100%";
        bar3.style.width = "100%";
        bar4.style.width = "100%";

    }

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                handleEfect();
            }, 500);
        }
    }, [inView])

    return (
        <div className='products' ref={ref1}>
            <div className='current-product'>
                <img className='current-im' src='assets2/updates (1).png' />
                <div className='product-bar-1' id='bar1'>

                </div>
                <p className='product-para'>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque officiis repellat modi ad odio? Omnis atque beatae molestiae ea tenetur?
                </p>
                <hr className='product-line'>
                </hr>
                <button className='product-button'>
                    View More
                    <FaArrowRight className='product-icon'/>
                </button>
            </div>
            <div className='current-product'>
                <img className='current-im' src='assets2/updates (3).png'
                />
                <div className='product-bar-2' id='bar2'>

                </div>
                <p className='product-para'>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque officiis repellat modi ad odio? Omnis atque beatae molestiae ea tenetur?
                </p>
                <hr className='product-line'>
                </hr>
                <button className='product-button'>
                    View More
                    <FaArrowRight className='product-icon'/>
                </button>
            </div>
            <div className='current-product'>
                <img className='current-im' src='assets2/updates (2).png' />
                <div className='product-bar-3' id='bar3'>

                </div>
                <p className='product-para'>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque officiis repellat modi ad odio? Omnis atque beatae molestiae ea tenetur?
                </p>
                <hr className='product-line'></hr>
                <button className='product-button'>
                    View More
                    <FaArrowRight className='product-icon'/>
                </button>
            </div>
            <div className='current-product'>
                <img className='current-im' src='assets2/updates (3).png' />
                <div className='product-bar-4' id='bar4'>

                </div>
                <p className='product-para'>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque officiis repellat modi ad odio? Omnis atque beatae molestiae ea tenetur?
                </p>
                <hr className='product-line'></hr>
                <button className='product-button'>
                    View More
                    <FaArrowRight className='product-icon'/>
                </button>
            </div>

        </div>
    )
}

export default Products