import React from 'react'
import { useInView } from 'react-intersection-observer';
import './LandingPoster.css';
import { useEffect } from 'react';

function LandingPoster() {

    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true
    })

    function hanfleEffect(){
        const element = document.querySelector('#text-1')
        const element2 = document.querySelector('#text-2')
        const element1 = document.querySelector('#text-3')
        const poster = document.querySelector('#poster')

        poster.style.opacity = "1";

        element.style.left = "9%";
        element1.style.left = "8%"

        element1.style.opacity = "1";
        element.style.opacity = "1";
        element2.style.opacity = "1";
    }

    useEffect(() => {
      if(inView){
        setTimeout(() => {
            hanfleEffect();
        }, 1000);
      }
    }, [inView])
    
    return (
        <div className='landing-poster ' ref={ref}>
            <div className='poster-text-container'>
                <p className='work-text'>
                    Gypsum Partition <br></br> Works
                </p>
                <p className='poster-text' id='text-1'>
                    DESIGN YOUR 
                </p>
                <div className='text-rect'>
                    <p className='dream-text' id='text-2'>
                        DREAM HOME
                    </p>
                </div>
                <p className='with-text' id='text-3'>
                    WITH US
                </p>
            </div>
            <img src='assets2/poster01.png' className='poster-01' id='poster' />
        </div>
    )
}

export default LandingPoster