import React, { useState, useEffect } from 'react';
import './About.css';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import LazyImage from '../components/LazyImage'; // Adjust path if necessary


function About() {
  const [typedText, setTypedText] = useState('');
  const [showCursor, setShowCursor] = useState(true);
  const fullText = 'Since We Started Work in 1980';

  // Intersection Observer Hook to detect when the component is in view
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger only once when the component enters the view
    threshold: 0.1, // The component is considered in view when 10% of it is visible
  });
  useEffect(() => {
    if (inView) {
      let index = 0;
      setTypedText(''); // Clear text before typing starts
      const intervalId = setInterval(() => {
        if (index <= fullText.length) {
          setTypedText(fullText.slice(0, index)); // Use slice to avoid state race condition
          index++;
        } else {
          clearInterval(intervalId);
          setShowCursor(false);
        }
      }, 100);
  
      return () => clearInterval(intervalId);
    }
  }, [inView, fullText]); 

  return (
    <div className="About" ref={ref}>
      <div className="about-left">
        <div className="my-im">
          <LazyImage className="im-1" src="012.jpg" alt="About" loading="lazy" />
        </div>
      </div>
      <div className="about-next">
        <div className="text-line-1">
          <p>WELCOME TO ND SERVICES</p>
        </div>
        <h1 className="about-head">
          {typedText}
          {showCursor && <span>|</span>} {/* Add cursor element */}
        </h1>
        <p className="about-para">
          At ND Gypsum & Partition Services, we specialize in delivering top-notch interior services designed to enhance the beauty, functionality, and comfort of your spaces. Whether it's transforming your home or upgrading your office, our skilled team is committed to providing high-quality solutions that cater to your unique needs. We offer a wide range of services, including gypsum partitions, false ceilings, flooring, and more, all crafted with precision and care.

          With years of expertise and a passion for design, we aim to bring your vision to life. Our mission is to create spaces that not only look amazing but also improve the way you live and work. Partner with us to transform your environment into something truly exceptional.
        </p>
        <div className="about-read-link">
          <Link to="about">
            <button className="read-more">Read More</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default About;
