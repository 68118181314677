import React, { useEffect } from 'react';
import './Services.css';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import LazyImage from '../components/LazyImage'; // Adjust path if necessary


function Services() {
    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    const handleEffect = () => {
        const elements = document.querySelectorAll('#current-service-id');
        elements.forEach((el) => {
            el.style.opacity = 1;
            el.style.marginTop = "-10px";
        });
    };

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                handleEffect();
            }, 100);
        }
    }, [inView]);

    return (
        <div className='Services' id='services' ref={ref}>
            <h1 className='services-head'>
                Our Services
            </h1>
            <div className='all-services'>
                <div className='current-service' id='current-service-id'>
                    <LazyImage src='assets2/partition4.jpeg' className='im-service-1' />
                    <h2 className='service-1-head'>
                        Gypsum Partition
                    </h2>
                    <p className='service-text'>
                    Gypsum partitions offer a versatile solution for dividing spaces, ensuring both privacy and functionality with easy installation and lasting durability.
                    </p>
                    <Link
                        to="/partition"
                    >
                        <button className='read-more'>
                            read more
                        </button>
                    </Link>
                </div>
                <div className='current-service' id='current-service-id'>
                    <LazyImage src='assets2/grass2.jpeg' className='im-service-1' />
                    <h2 className='service-1-head'>
                        Artificial Grass
                    </h2>
                    <p className='service-text'>
                    Enjoy a lush, maintenance-free lawn year-round with our artificial grass solutions, perfect for homes, gardens, and commercial spaces.
                    </p>
                    <Link
                        to="/grass"
                    >
                        <button className='read-more'>
                            read more
                        </button>
                    </Link>
                </div>
                <div className='current-service' id='current-service-id'>
                    <LazyImage src='tiles.jpg' className='im-service-1' />
                    <h2 className='service-1-head'>
                        Tile Fixing
                    </h2>
                    <p className='service-text'>
                    Get flawless tile installations with expert precision, enhancing your walls, floors, and backsplashes with a variety of stylish materials and designs.
                    </p>
                    <Link
                        to="/tile"
                    >
                        <button className='read-more'>
                            read more
                        </button>
                    </Link>
                </div>
                <div className='current-service' id='current-service-id'>
                    <LazyImage src='e4.jpg' className='im-service-1' />
                    <h2 className='service-1-head'>
                        Eletrician Services
                    </h2>
                    <p className='service-text'>
                    Ensure safety and efficiency with our certified electrician services, providing reliable installations and solutions for all your electrical needs.
                    </p>
                    <Link
                        to="/electrical"
                    >
                        <button className='read-more'>
                            read more
                        </button>
                    </Link>
                </div>
            </div>
            <div className='all-services'>
                <div className='current-service' id='current-service-id'>
                    <LazyImage src='paint.jpg' className='im-service-1' />
                    <h2 className='service-1-head'>
                        Paint Services
                    </h2>
                    <p className='service-text'>
                    Transform your space with vibrant, high-quality painting services that bring color, life, and character to any room, professionally finished for lasting beauty.
                    </p>
                    <Link
                        to="/paint"
                    >
                        <button className='read-more'>
                            read more
                        </button>
                    </Link>
                </div>
                <div className='current-service' id='current-service-id'>
                    <LazyImage src='marble.jpg' className='im-service-1' />
                    <h2 className='service-1-head'>
                        Marble Flooring
                    </h2>
                    <p className='service-text'>
                    Add timeless elegance to your floors with premium marble flooring that is durable, stylish, and perfect for both residential and commercial settings.
                    </p>
                    <Link
                        to="/marble"
                    >
                        <button className='read-more'>
                            read more
                        </button>
                    </Link>
                </div>
                <div className='current-service' id='current-service-id'>
                    <LazyImage src='assets2/ceil1.jpeg' className='im-service-1' />
                    <h2 className='service-1-head'>
                        False Ceiling
                    </h2>
                    <p className='service-text'>
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software.
                    </p>
                    <Link
                        to="/ceiling"
                    >
                        <button className='read-more'>
                            read more
                        </button>
                    </Link>
                </div>
                <div className='current-service' id='current-service-id'>
                    <LazyImage src='assets2/ceil4.jpeg' className='im-service-1' />
                    <h2 className='service-1-head'>
                        Gypsum Ceiling
                    </h2>
                    <p className='service-text'>
                    Elevate your interiors with false ceilings that improve aesthetics, conceal utilities, and enhance lighting and acoustics for a refined, modern look.
                    </p>
                    <Link
                        to="/ceiling"
                    >
                        <button className='read-more'>
                            read more
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Services;
