import React from 'react';
import './AboutContent.css';

function AboutContent() {
  return (
    <div className='AboutContent'>
        <h1 className='about-head-1'>
            Why Choose Us?
        </h1>
        <p className='about-para-1'>
            At ND Ypsum Partition Services, we strive to deliver unparalleled quality and value, making us the preferred choice for interior design solutions in Dubai. Here’s why you should choose us:
            <ul>
              <li><b>Decades of Expertise:</b> With over 40 years of experience, we bring a wealth of knowledge and craftsmanship to every project.</li>
              <li><b>Tailored Solutions:</b> We understand that every space is unique. Our team works closely with you to create designs that perfectly align with your vision and needs.</li>
              <li><b>Premium Quality Materials:</b> We use only the highest quality materials, ensuring durability and a flawless finish in every design.</li>
              <li><b>Skilled Professionals:</b> Our team comprises highly skilled designers and craftsmen dedicated to turning your ideas into reality.</li>
              <li><b>Client-Centric Approach:</b> Your satisfaction is our priority. From concept to completion, we focus on delivering exceptional results that exceed your expectations.</li>
            </ul>
            Choose ND Ypsum Partition Services to transform your spaces into inspiring interiors with style, functionality, and precision.
        </p>
    </div>
  );
}

export default AboutContent;
