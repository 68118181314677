import React from 'react';
import './Maps.css';

function Maps() {
  return (
    <div className="maps-container">
      <div className="maps-next">
        <iframe
          className="map-location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.6110936885557!2d55.3259839!3d25.283664899999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5ddd82a18659%3A0x71cce6b292d520c4!2sND%20Gypsum%20%26%20Partition%20Services!5e0!3m2!1sen!2s!4v1734981130901!5m2!1sen!2s"
          allowFullScreen=""
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}

export default Maps;
