import React from 'react'
import './Updates.css';

function Updates() {
  return (
    <div className='updates'>
        <h1 className='updates-title'> 
            Updates
        </h1>
        <div className='update-posters'>
            <img src='assets2/updates (1).png' className='im-update'/>
            <img src='assets2/updates (2).png' className='im-update'/>
            <img src='assets2/updates (3).png' className='im-update'/>
        </div>
    </div>
  )
}

export default Updates