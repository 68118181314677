import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Contact from './pages/Contact';
import About from './pages/AboutPage';
import Partition from './pages/Partition';
import MarbleFloor from './pages/MarbleFloor'
import Ceiling from './pages/Ceiling'
import Paint from './pages/Paint';
import Grass from './pages/Grass'
import Electrical from './pages/Electrical';
import Tile from './pages/Tile';
import ScrollToTopButton from './components/ScrollToTopButton';
import ServicesDisplay from './components/ServicesDisplay';
import Footer from './components/Footer';
import Whatsapp from './components/Whatsapp';
import ScrollToTop from './components/ScrollToTop';
import LandingPage from './pages/LandingPage';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <ScrollToTop/>
        <Whatsapp/>
        <ServicesDisplay />
        <ScrollToTopButton />
        <Routes>
          <Route path="/" element={<LandingPage/>}/>
          <Route path="home" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path='/partition' element={<Partition />} />
          <Route path='/marble' element={<MarbleFloor />} />
          <Route path='/ceiling' element={<Ceiling />} />
          <Route path='/paint' element={<Paint />} />
          <Route path='/grass' element={<Grass />} />
          <Route path='/electrical' element={<Electrical />} />
          <Route path='/tile' element={<Tile />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
