import React, { useEffect, useState } from 'react';
import './GypsumBoards.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function GypsumBoards() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 500);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="boards-outer">
            <div className="land-content">
                <div className="partition-top">
                    <h1 className="partition-head-1">
                        Gypsum Partition Boards: Your Space, Your Way
                    </h1>
                    <p className="partition-para">
                        At ND Gypsum Partition, we specialize in providing high-quality gypsum partition services tailored to your space and design needs. Whether you're looking to divide a room for privacy or create a more functional layout, our team of experienced professionals ensures precision and durability in every project.
                    </p>
                </div>
            </div>
            {isMobile ? (
                <Swiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={25}
                    slidesPerView={1}
                    navigation={{
                        nextEl: '.custom-next',
                        prevEl: '.custom-prev',
                    }}
                    pagination={{ clickable: true }}
                >
                    <SwiperSlide>
                        <div className="current-board">
                            <img className="im-board" src="assets2/board (1).jpeg" alt="Board 1" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="current-board">
                            <img className="im-board" src="assets2/board (2).jpeg" alt="Board 2" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="current-board">
                            <img className="im-board" src="assets2/board (3).jpeg" alt="Board 3" />
                        </div>
                    </SwiperSlide>
                    <div className="custom-prev">
                        <FaChevronLeft />
                    </div>
                    <div className="custom-next">
                        <FaChevronRight />
                    </div>
                </Swiper>
            ) : (
                <div className="boards">
                    <div className="current-board">
                        <img className="im-board" src="assets2/board (1).jpeg" alt="Board 1" />
                    </div>
                    <div className="current-board">
                        <img className="im-board" src="assets2/board (2).jpeg" alt="Board 2" />
                    </div>
                    <div className="current-board">
                        <img className="im-board" src="assets2/board (3).jpeg" alt="Board 3" />
                    </div>
                </div>
            )}
        </div>
    );
}

export default GypsumBoards;
