import React from 'react'
import './GypsumServices.css';
import { Link } from 'react-router-dom';

function GypsumServices() {
    return (
        <div className='Gypsum-services'>
            <h1 className='head'>
                Gypsum Services
            </h1>
            <div className='Gypsum-services-layout'>
                <div className='column-current'>
                <div className='column-circle'>
                        <img src='assets2/ceil4.jpeg' />
                    </div>
                    <h3 className='Gypsum-service-head'>
                        Gypsum Ceiling
                    </h3>
                    <p className='Gypsum-service-text'>
                    Elevate your interiors with false ceilings that improve aesthetics, conceal utilities, and enhance lighting and acoustics for a refined, modern look
                    </p>
                    <Link to={'/ceiling'}>
                    <button className='read-more'>
                        Read More
                    </button>
                    </Link> 
                </div>
                <div className='column-current'>
                    <div className='column-circle'>
                        <img src='assets2/partition2.jpeg' />
                    </div>
                    <h3 className='Gypsum-service-head'>
                        Gypsum Walls
                    </h3>
                    <p className='Gypsum-service-text'>
                        Gypsum partitions offer a versatile solution for dividing spaces, ensuring both privacy and functionality with easy installation and lasting durability.
                    </p>
                    <Link to={'/partition'}>
                    <button className='read-more'>
                        Read More
                    </button>
                    </Link>
                </div>
                <div className='column-current'>
                <div className='column-circle'>
                        <img src='assets2/partition4.jpeg' />
                    </div>
                    <h3 className='Gypsum-service-head'>
                        Gypsum Partition
                    </h3>
                    <p className='Gypsum-service-text'>
                        Gypsum partitions offer a versatile solution for dividing spaces, ensuring both privacy and functionality with easy installation and lasting durability.
                    </p>
                    <Link to={'/partition'}>
                    <button className='read-more'>
                        Read More
                    </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default GypsumServices