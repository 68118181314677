import React from 'react'
import './PartitionContent.css'
import LazyImage from '../components/LazyImage'; // Adjust path if necessary
import { FaShieldAlt, FaCubes, FaTools } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './PaintContent.css';


function PaintContent() {

    const images = [
        'c1 (2).jpg',
        'c2.jpg',
        'c3.jpg',
        'p4.jpg',
        'p5.jpg',
        'p6.jpg',
        'p7.jpg',
        'p8.jpg',
        'p9.jpg',
    ];

    const openWhatsapp = (phoneNumber) => {
        const whatsappUrl = `https://wa.me/${phoneNumber}`;
        window.open(whatsappUrl, '_blank');
    };


    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };


    const updateIndicators = (index) => {
        const indicators = document.querySelectorAll(".slide-counter");
        indicators.forEach((indicator, i) => {
            if (i === index) {
                indicator.style.backgroundColor = "rgb(51, 89, 119)";
                indicator.style.height = "11px";
                indicator.style.width = "11px";
            } else {
                indicator.style.backgroundColor = "white";
                indicator.style.height = "8px";
                indicator.style.width = "8px";
            }
        });
    };

    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    const handleEffect = () => {
        const bar1 = document.querySelector('.bar-inner-1');
        bar1.style.width = '87%';
        const bar2 = document.querySelector('.bar-inner-2');
        bar2.style.width = '92%';
        const bar3 = document.querySelector('.bar-inner-3');
        bar3.style.width = '95%';
        const bar4 = document.querySelector('.bar-inner-4');
        bar4.style.width = '90%';
    }

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                handleEffect();
            }, 500);
        }
    }, [inView])

    useEffect(() => {
        updateIndicators(currentIndex);
    }, [currentIndex]);

    return (
        <div className='PartitionContent'>
            <div className="PaintContent">
                <div className='partition-banner-inner'>
                    <h1 className='partition-head'>
                        Paint Services
                    </h1>
                </div>
            </div>
            <div className='partition-container'>
                <div className='partition-top'>
                    <h1 className='partition-head-1'>
                    Paint Services: Refresh and Revitalize Your Space
                    </h1>
                    <p className='partition-para'>
                    ND Paint Services offers expert painting solutions to give your home or office a fresh and vibrant look. Our team of skilled painters uses high-quality paints and techniques to deliver a flawless finish that adds color and character to your environment. From interior walls to exteriors, we provide both residential and commercial painting services, ensuring precision in every coat. Whether you want to brighten up a room, create a feature wall, or completely transform your space, our paint services are designed to bring your vision to life.
                    </p>
                </div>
                <div className='slider' >
                    <button className='slider-button prev' onClick={prevSlide}>
                        &#8249;
                    </button>
                    <div className='slider-images'>
                        {images.slice(currentIndex, currentIndex + 3).map((image, index) => (
                            <div key={index} className='slider-image'>
                                <LazyImage src={image} alt={`Slide ${index}`} loading="lazy" />
                            </div>
                        ))}
                    </div>
                    <button className='slider-button next' onClick={nextSlide}>
                        &#8250;
                    </button>
                </div>
                <div className="slider-indicators">
                    {images.map((_, index) => (
                        <div
                            key={index}
                            className={`slide-counter`}
                        ></div>
                    ))}
                </div>
            </div>
            <div className='partition-content-top'>
                <h1 className='partition-head-2'>
                    How We Deliver Paint Services
                </h1>
                <p className='partition-para'>
                Paint services are an essential part of home and business renovation, focusing on the application of high-quality paints to both interior and exterior surfaces. Painting not only enhances the visual appeal of a room but also protects surfaces from wear and tear caused by environmental factors. Our expert painters use the best materials and techniques, ensuring that every surface is properly prepared and finished for a flawless, long-lasting result. Paint can be applied to walls, ceilings, windows, doors, and even furniture, giving an entire space a fresh and updated look. Whether you’re looking to create a dramatic accent wall, revitalize the exterior of your property, or simply refresh a tired room, our professional paint services provide the perfect solution. We offer a wide range of finishes, including matte, satin, and gloss, each providing its own unique effect and texture. We work with you to select the best colors and finishes for your space, whether it’s a vibrant palette or more neutral tones. Beyond aesthetics, our paints also offer benefits such as better protection against moisture, UV rays, and pollutants, making your space not only beautiful but more durable.
                </p>
                <h1 className='partition-head-2'>
                Benefits of Using Our Paint Service
                </h1>
                <ul>
                    <li>A simple yet impactful way to completely refresh any room or exterior.</li>
                    <li>High-quality paint protects surfaces from wear, fading, and environmental damage.</li>
                    <li>Choose from a range of textures and finishes for different effects.</li>
                    <li>We offer eco-conscious, low-VOC paint choices that are healthier for your home and the environment.</li>
                    <li>Ensures a smooth, even coat without streaks, drips, or uneven coverage.
                    </li>
                </ul>
            </div>

            <div className='content-container'>
                <div className='current-section'>
                    <FaShieldAlt color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Durable Gypsum Walls
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
                <div className='current-section'>
                    <FaCubes color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Elegant Design
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
                <div className='current-section'>
                    <FaTools color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Easy Installation
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
            </div>
            <div className='partition-content-bottom' >
                <div className='content-bottom-left'>
                    <h1 className='partition-head-2'>
                    Imagine a home that radiates warmth and character with every stroke of color.
                    </h1>
                    <p className='partition-para-bottom'>
                    Our professional painting services are designed to give your space a fresh, vibrant look. At ND Paint Services, we help you choose the right colors and finishes to elevate the ambiance of your home or office. Our painters use the highest-quality materials to ensure a smooth and flawless finish, making your walls stand out in the best way possible. Whether you're looking to create a bold accent wall or need a complete color overhaul, we’re here to provide top-notch results. Say goodbye to dull, faded walls and hello to a revitalized space that reflects your style.
                    </p>
                    <button className='read-more' onAbort={openWhatsapp}>
                        Contact Now
                    </button>
                </div>
                <div className='content-bottom-next'>
                    <LazyImage src='p7.jpg' className='content-partiton-im'/>
                </div>
            </div>
        </div>
    )
}

export default PaintContent