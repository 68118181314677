import React, { useState, useEffect } from 'react';
import './LandReviews.css';

function LandReviews() {
  

  return (
    <div className='land-reviews'>
      <p>
        No Reviews Available
      </p>
    </div>
  );
}

export default LandReviews;
