import React from 'react'
import './AboutContainer.css'
import LazyImage from '../components/LazyImage'; // Adjust path if necessary


function AboutContainer() {
  return (
    <div className='AboutContainer'>
      <div className='about-container-left'>
        <LazyImage src='abt.jpg' alt='?' className='im-about' />
      </div>
      <div className='about-container-next'>
        <div className='text-line-1'>
          <p>
            WELCOME TO ND SERVICES
          </p>
        </div>
        <h1 className='about-head'>
          Since We <br>
          </br> Started Work in <br>
          </br> 1980
        </h1>
        <p className='about-para'>
          ND Ypsum Partition Services has been a trusted name in delivering high-quality interior design solutions since 1980. With decades of experience, we specialize in transforming spaces with our innovative and precise gypsum partitioning, ensuring functionality and aesthetic appeal.

          Our commitment to excellence and attention to detail have made us a leader in the industry, catering to residential, commercial, and industrial clients alike. Whether you need sleek office partitions, elegant home interiors, or durable solutions for larger projects, we bring creativity and craftsmanship to every design.

          At ND Services, we pride ourselves on using premium materials and modern techniques to create tailored designs that align perfectly with your vision. Our team of skilled professionals is dedicated to turning your ideas into reality, delivering results that exceed expectations.

          Experience the perfect blend of style, durability, and functionality with ND Ypsum Partition Services — your trusted partner for exceptional interiors.
        </p>
      </div>
    </div>
  )
}

export default AboutContainer