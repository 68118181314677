import React from 'react';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import './Footer.css'; 

function Footer() {
  return (
    <div className="footer">
      <div className="footer-column">
        <h3>Our Services</h3>
        <a href="/partition">Gypsum Partition</a>
        <a href="/ceiling">Ceiling Installation</a>
        <a href="/paint">Paint Services</a>
        <a href="/grass">Artificial Grass</a>
      </div>
      <div className="footer-column">
        <h3>Additional Services</h3>
        <a href="/ceiling">Gypsum Ceiling</a>
        <a href="/electrical">Electrical Work</a>
        <a href="/tile">Tile Fixing</a>
        <a href="/marble">Marble Flooring</a>
      </div>
      <div className="footer-column">
        <h3>Quick Links</h3>
        <a href="/">Home</a>
        <a href="/about">About</a>
        <a href="/contact">Contact</a>
        <a href="/">Services</a>
      </div>
      <div className="footer-column">
        <h3>Contact Us</h3>
        <div className="footer-social">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
        </div>
        <p>Address: ND Gypsum & Partition Services, Abu Hail - Dubai - United Arab Emirates</p>
        <p>Phone: +971568522584</p>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Abdullah Mazher. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
