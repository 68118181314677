import React from 'react'
import './AboutContainer1.css'
import LazyImage from '../components/LazyImage'; // Adjust path if necessary


function AboutContainer1() {
    return (
        <div className='AboutContainer1'>
            <div className='about-container1-left'>
                <h1 className='about-container-head-1'>
                    Best Interior <br></br> Design Company in <br></br> Dubai
                </h1>
                <p className='about-bottom-text'>
                    ND Ypsum Partition Services takes pride in being recognized as one of the leading interior design companies in Dubai. Our expertise lies in creating modern, functional, and visually stunning spaces tailored to meet the unique needs of our clients.

                    From contemporary office designs to sophisticated home interiors, we offer innovative solutions that blend practicality with elegance. Our team of experienced designers and skilled craftsmen ensures every project is executed with precision, using the finest materials and techniques.

                    At ND Services, we understand that every space tells a story, and we are here to bring your vision to life. Whether it's enhancing your workspace's efficiency or elevating the comfort of your home, we are committed to delivering exceptional results that reflect your style and personality.

                    Discover unparalleled craftsmanship and design excellence with ND Ypsum Partition Services, your trusted partner in creating inspiring interiors.
                </p>
            </div>
            <div className='about-container1-next'>
                <LazyImage src='abt2.jpg' alt='?' className='im-about-1' />
            </div>
        </div>
    )
}

export default AboutContainer1