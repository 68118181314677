import React, { useState } from "react";
import "./QAComponent.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

function QAComponent() {
  const [activeIndex, setActiveIndex] = useState(null);

  const questionsAnswers = [
    {
      question: "What is the pricing for basic interior design services?",
      answer: "Our basic interior design services start at $500, depending on the project size and requirements.",
    },
    {
      question: "Do you offer customized pricing plans?",
      answer: "Yes, we offer customized pricing plans tailored to your specific needs and preferences.",
    },
    {
      question: "Are there any hidden charges?",
      answer: "No, we ensure transparency in our pricing, and all costs will be clearly outlined in the contract.",
    },
    {
      question: "Do you offer discounts for bulk projects?",
      answer: "Yes, discounts are available for bulk or long-term projects. Contact us for more details.",
    },
  ];

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="qa-container">
      <h1 className="qa-title">Frequently Asked Questions</h1>
      <div className="qa-list">
        {questionsAnswers.map((item, index) => (
          <div key={index} className="qa-item">
            <div
              className="qa-question"
              onClick={() => toggleAnswer(index)}
            >
              <span>{item.question}</span>
              {activeIndex === index ? (
                <FaChevronUp className="qa-icon" />
              ) : (
                <FaChevronDown className="qa-icon" />
              )}
            </div>
            {activeIndex === index && (
              <div className="qa-answer">{item.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default QAComponent;
