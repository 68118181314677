import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './ServicesDisplay.css'
import { FaAngleDoubleRight } from 'react-icons/fa';
import LazyImage from '../components/LazyImage'; // Adjust path if necessary

import { FaFacebook, FaTwitter, FaInstagram, FaWhatsapp, FaPhone, FaChevronDown, FaPaintBrush, FaHammer, FaWrench, FaTshirt, FaTools, FaPlug, FaSearch } from 'react-icons/fa';

function ServicesDisplay() {
    const [isVisible, SetIsVisible] = useState(false);
    const [isBoxOpen, setIsBoxOpen] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            SetIsVisible(true);
        }, 1000);
    }, []);

    const toggleBox = () => {
        setIsBoxOpen(!isBoxOpen);
    };

    if (isVisible) {
        return (
            <>
                <div
                    className="slide-icon-services"
                    style={{
                        position: 'fixed',
                        top: '33%',
                        left: '-5px',
                        padding: '5px',
                        fontSize: '18px',
                        borderRadius: '10px',
                        color: '#1a1a1a',
                        cursor: 'pointer',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        zIndex: 1000,
                        transition: '0.5s ease',
                    }}
                    onClick={toggleBox}
                >
                    <FaAngleDoubleRight className='slide-icon-services' />
                </div>

                <div
                    className="services-box"
                    style={{
                        position: 'fixed',
                        top: '38%',
                        left: isBoxOpen ? '0' : '-400px',
                        width: '200px',
                        height: '90vh',
                        transition: '0.5s ease',
                        padding: '5px',
                        paddingTop: '10px',
                        zIndex: 999,
                        overflowY: 'auto',
                        backgroundColor: '#f9f9f9',
                        borderRadius: '10px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        border: '1px solid #ddd',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {/* First Column of Services */}
                    <div className='side-services'>
                        <Link
                            to="/electrical"
                        >
                            <FaPlug className='dropdown-icon' />
                            Electrician Services
                        </Link>
                        <Link
                            to="/partition"
                        >
                            <FaHammer className='dropdown-icon' />
                            Gypsum Partition
                        </Link>
                        <Link
                            to="/ceiling"

                        >
                            <FaWrench className='dropdown-icon' />
                            Gypsum Ceiling
                        </Link>
                        <Link
                            to="/marble"

                        >
                            <FaTshirt className='dropdown-icon' />
                            Marble Flooring
                        </Link>
                        <Link
                            to="/grass"

                        >
                            <FaTools className='dropdown-icon' />
                            Artificial Grass
                        </Link>
                        <Link
                            to="/tile"
                        >
                            <FaTools className='dropdown-icon' />
                            Tile Fixing
                        </Link>
                        <Link
                            to="/paint"
                        >
                            <FaPaintBrush className="dropdown-icon" />
                            Painting Services
                        </Link>
                    </div>

                </div>
            </>
        );
    }
}

export default ServicesDisplay;
