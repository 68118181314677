import React, { useState, useEffect } from 'react';
import './Analytics.css';
import { useInView } from 'react-intersection-observer';

function Analytics() {

 const[ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
 })

 const handleEffect = () =>{
    const element = document.querySelector('.a-bar-1');
    element.style.width = "70%"
    const element1 = document.querySelector('.a-bar-2');
    element1.style.width = "70%"
    const element2 = document.querySelector('.a-bar-3');
    element2.style.width = "80%"
    const element3 = document.querySelector('.a-bar-4');
    element3.style.width = "80%"
 }

 useEffect(() => {
   if(inView){
    setTimeout(() => {
        handleEffect();
    }, 500);
   }
 }, [inView])
 

  const [happyCustomers, setHappyCustomers] = useState(0);
  const [countriesServed, setCountriesServed] = useState(0);
  const [successfulProducts, setSuccessfulProducts] = useState(0);
  const [yearsOfExcellence, setYearsOfExcellence] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (happyCustomers < 3000) setHappyCustomers(prev => prev + 100);
      if (countriesServed < 80) setCountriesServed(prev => prev + 5);
      if (successfulProducts < 300) setSuccessfulProducts(prev => prev + 10);
      if (yearsOfExcellence < 10) setYearsOfExcellence(prev => prev + 1);
    }, 200);

    return () => clearInterval(interval);
  }, [happyCustomers, countriesServed, successfulProducts, yearsOfExcellence]);

  return (
    <div className="analytics" ref={ref}>
      <div className="analytics-grid">
        <div className="analytics-item">
        <p className="analytics-number">{happyCustomers}+</p>
          <h2 className="analytics-title">Happy Customers</h2>
          <div className='analytics-bar a-bar-1'>

          </div>
         
        </div>
        <div className="analytics-item">
        <p className="analytics-number">{countriesServed}+</p>
          <h2 className="analytics-title">Countries Served</h2>
          <div className='analytics-bar a-bar-2'>

          </div>
          
        </div>
        <div className="analytics-item">
        <p className="analytics-number">{successfulProducts}+</p>
          <h2 className="analytics-title">Successful Products</h2>
          <div className='analytics-bar a-bar-3'>

          </div>
          
        </div>
        <div className="analytics-item">
        <p className="analytics-number">{yearsOfExcellence}+</p>
          <h2 className="analytics-title">Years of Excellence</h2>
          <div className='analytics-bar a-bar-4'>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Analytics;
